import React from 'react'
import { Heading, Box } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3,
    span: {
      color: `omegaDark`
    },
  },
  heading: {
    color: `alpha`,
    textAlign: 'right',
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    maxWidth: '500px',
    textAlign: 'right',
    float: 'right',
  },
  runningH3: {
    color: `omegaDark`,
    fontWeight: `body`,
    maxWidth: '500px',
    textAlign: 'right',
    float: 'right',
    fontSize: '20pt',
  }
}

const TestimonialContent = () => (
  <Box sx={styles.wrapper}>
    <Heading variant='h1' sx={styles.heading}>
      <span>Bite-Sized</span> Lessons for Everyone
    </Heading>
    <Heading variant='h1' sx={styles.running}>
      Applicable right away
    </Heading>
    <Heading variant='h3' sx={styles.runningH3}> </Heading>
  </Box>
)

export default TestimonialContent