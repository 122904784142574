import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    },
    color: 'alpha',
    marginBottom: '2%'
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`,
  }
}

const HeroContent = () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
        High Quality<span> Summaries</span><br></br> in 5 min Articles.
      </Heading>
      <Heading variant='h2' sx={styles.running}>
        "Writing a short summary about what you learn helps deepen your knowledge. I thought why not sharing them as well!"
      </Heading>
      <Heading variant='h3' sx={styles.running}>
        Fabian Jost, CEO of LYTE Vapes
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      {/*}
      <Button as={Link} to='/'>
        Start Learning
      </Button>
      */}
      <Button variant='white' as={Link} to='/about' style={{marginTop: '15px'}}>
        About Me
      </Button><Button variant='white' as={Link} to='https://lytevapes.com' style={{marginTop: '15px'}}>
        My Business
      </Button>
    </Box>
  </>
)

export default HeroContent